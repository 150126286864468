import React from "react";

import Layout from "../components/layout-2";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";
import MenuDrawer from "../components/atoms/menuDrawer";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found | GÁBOR TAMÁS" />
      <div style={{height: `calc(100vh - 240px)`}}>
        <img
          alt="Ghost getting abducted by aliens"
          className="block w-1/2 mx-auto mt-16 md:mt-10 md:w-1/4"
          src={abductionIllustration}
        />
        <h2 className="inline-block w-full p-3 my-8 text-2xl font-bold bg-yellow-400">
          Looks like this page is a ghost that got abducted by aliens...
        </h2>
      </div>
    <MenuDrawer/>
    </Layout>
  );
}

export default NotFoundPage;
